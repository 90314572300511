@import './font.scss';

.mid {
  @include medium;
}

.mid16 {
  @include medium;
  font-size: 1rem;
}

.mid14 {
  @include medium;
  font-size: 0.875rem;
}

.mid12 {
  @include medium;
  font-size: 0.75rem;
}

.mid20 {
  @include medium;
  font-size: 1.25rem;
}

.mid24 {
  @include medium;
  font-size: 1.5rem;
}

.mid20 {
  @include regular;
  font-size: 1.25rem;
}

.reg12 {
  @include regular;
  font-size: 0.75rem;
}

.reg14 {
  @include regular;
  font-size: 0.875rem;
}

.reg16 {
  @include regular;
  font-size: 1rem;
}

.reg18 {
  @include regular;
  font-size: 1.125rem;
}

.smbold24 {
  @include semibold;
  font-size: 1.5rem;
}
