.list_wrapper {
  margin-bottom: 24px;
}

.list_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  height: 40px;
}

.logo_wrapper {
  overflow: hidden;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  margin-right: 16px;
}

.logo_wrapper img {
  width: 100%;
  height: 100%;

}

.bank_detail_wrapper {
  display: flex;
  align-items: center;
}
