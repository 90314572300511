@mixin regular {
  font-family: upgrade, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin medium {
  font-family: upgrade, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin semibold {
  font-family: upgrade, sans-serif;
  font-weight: 500;
  font-style: normal;
}
