@import url('https://use.typekit.net/sob5jke.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'upgrade', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.stat {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 8px;
  margin-bottom: 16px;
  align-items: center;
}

.stat_wrapper {
  background: #ffffff;
  width: 100%;
  height: 100px;
  padding: 24px 0 24px 24px;
  border-radius: 6px;
  margin-bottom: 16px;
}

.stat_wrapper:not(:last-child) {
  margin-right: 27px;
}

.chakra-modal__overlay {
  background: rgba(48, 63, 78, 0.74);
  background-blend-mode: multiply;
}

.chakra-modal__content-container {
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.chakra-modal__content {
  background: #fff;
  width: 500px !important;
  height: fit-content;
  border-radius: 8px;
}

.chakra-modal__body {
  padding: 32px;
}

.modal_btn_container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
}

.modal_header {
  margin-bottom: 20px;
}

.modal_btn {
  height: 50px;
  border-radius: 8px;
  border: 1px solid #2054d2;
  color: #2054d2;
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 16px;
}

.modal_btn_main {
  color: #fff;
  background-color: #2054d2;
  width: 100%;
}

.drawer_content {
  height: 100vh;
  border-radius: 0px;
}

.wide_drawer {
  width: 70% !important;
}

.benefits {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.close_drawer {
  background: white;
  position: absolute;
  top: 18px;
  left: -34px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.benefits_body {
  display: flex;
  flex-direction: column;
}

.form_error {
  color: red;
  margin-bottom: 4px;
}

.badge {
  padding: 2px 4px;
  border-radius: 10px;
  background: red;
  color: #fff;
}

input[type='search'] {
  -webkit-appearance: none;
}

/* LOADER */

.loader_bg {
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* LOADER */
